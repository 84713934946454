import Image from "next/image";
import Link from "next/link";

import useScreenWidth from "@/hooks/useScreenWidth";

const TopBannerImageMobile = ({ banner }) => {
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 480;

  return (
    <Link
      title={banner.bannerInfo.title}
      aria-label={banner.bannerInfo.title}
      href={banner?.bannerInfo?.link ?? ""}
      target="_blank"
      rel="sponsored"
      data-event-label="seasonal-banner"
    >
      <Image
        className="h-[80px] w-full rounded-lg"
        src={banner.bannerInfo.src}
        alt={banner.bannerInfo.title}
        loading="eager"
        priority
        width={isMobile ? 300 : 900}
        height={100}
        sizes="(max-width: 480px) 300px, 900px"
        quality={65}
      />
    </Link>
  );
};

export default TopBannerImageMobile;
