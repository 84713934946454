import ConsensusPicks from "@/components/consensusPicks/ConsensusPicks";
import Content from "@/components/Content";
import SportsbookPromosSkeleton from "@/components/skeleton/SportsbookPromosSkeleton";
import SportsbookPromos from "@/components/SportsbookPromos";
import { useAppContext } from "@/hooks/useAppContext";

import TopBannerImageMobile from "../TopBannerImageMobile";

const SidebarLayout = ({ children, siteContent }) => {
  const { affiliates, banners } = useAppContext();
  const mobileBanner = banners?.find(
    (banner) => banner.title === "banner-mobile"
  );
  const isMobileBannerActive = mobileBanner?.bannerInfo.active;

  return (
    <>
      <div className="container grid grid-cols-1 px-0 lg:grid-cols-[66.6%_33.3%] xl:max-w-screen-2xl xl:grid-cols-[25%_50%_25%]">
        <div className="hidden p-4 xl:block">
          <SportsbookPromos
            containerClass="sticky top-[6.5rem] py-10"
            affiliates={affiliates}
          />
        </div>
        <div className="px-5 pb-6 pt-4 lg:py-14">
          {isMobileBannerActive && (
            <div className="mb-4 h-auto w-full sm:hidden">
              <TopBannerImageMobile banner={mobileBanner} />
            </div>
          )}
          {children}
        </div>
        <div className="hidden p-4 lg:block">
          <ConsensusPicks />
        </div>
      </div>
      {siteContent && <Content content={siteContent} />}
    </>
  );
};

export default SidebarLayout;
