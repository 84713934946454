import labels from "@/config/labels";

import SportsbookPromoItem from "./SportsbookPromoItem";

const SportsbookPromos = ({
  affiliates,
  containerClass = "",
  withTitle = true,
  withOfferButtons = false,
  isTopBanner = false,
}) => {
  const containerClassname = containerClass || "";

  return (
    <div className={containerClassname}>
      {withTitle && (
        <p className="mb-2 text-xs font-medium uppercase tracking-wider text-darker-grey">
          {labels.sportsbookPromosHeader}
        </p>
      )}
      <ul className="grid grid-cols-1 divide-y divide-grey-200 rounded-lg border border-grey-200 bg-white">
        {affiliates?.map((sportsbook, index) => (
          <SportsbookPromoItem
            key={index}
            sportsbook={sportsbook}
            isTopBanner={isTopBanner}
            withOfferButtons={withOfferButtons}
          />
        ))}
      </ul>
    </div>
  );
};

export default SportsbookPromos;
