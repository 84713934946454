import Image from "next/image";
import Link from "next/link";

import labels from "@/config/labels";

const SportsbookPromoItem = ({
  sportsbook,
  withOfferButtons,
  isTopBanner,
  withPrefix = false,
}) => {
  const imageCtaContainerClassname = withOfferButtons
    ? "flex flex-col sm:flex-row sm:items-center"
    : withPrefix
      ? "flex flex-col md:flex-row items-center"
      : "flex flex row items-center";
  const ctaClassname = withOfferButtons
    ? "mt-1 font-heading text-[.85rem] sm:text-base font-medium text-link-blue hover:underline"
    : `font-heading text-[10px] md:text-xs font-bold md:font-medium text-link-blue hover:underline ${
        withPrefix
          ? "underline absolute md:static w-full md:right-0 right-4 md:text-start text-end top-14"
          : ""
      }`;
  const ctaLabel = withPrefix
    ? `${labels.picksCTAPrefix} ${sportsbook?.affiliateCta?.replace("»", "")} ${
        labels.picksCTAPostFix
      } ${sportsbook?.title} ${labels.quickCapCTAPostfix}`
    : sportsbook?.affiliateCta;
  return (
    <div className="flex items-center justify-between px-5 py-3">
      <div className={imageCtaContainerClassname}>
        <Image
          className="mr-4 h-[30px] w-[94px]"
          alt={sportsbook?.title}
          height={30}
          src={sportsbook?.affiliateImageUrl}
          width={94}
        />
        {withOfferButtons ? (
          <>
            <p className="mt-1 font-heading text-[.85rem] font-medium text-link-blue">
              {ctaLabel}
            </p>
          </>
        ) : (
          <Link
            aria-label={sportsbook?.affiliateCta}
            className={ctaClassname}
            href={sportsbook?.affiliateUrl ?? ""}
            target="__blank"
            prefetch={false}
            rel="sponsored"
            data-event-label={`${sportsbook?.title?.toLowerCase()}-sportsbook-${
              isTopBanner ? "picks-list-matchup" : "left-cta"
            }`}
          >
            {ctaLabel}
          </Link>
        )}
      </div>
      {withOfferButtons && (
        <Link
          aria-label={sportsbook?.buttonLabel}
          className="h-[34px] w-[110px] rounded-full bg-green text-center font-heading text-[.81rem] font-medium leading-[2.125rem] text-white md:w-[125px] md:text-[0.85rem]"
          href={sportsbook?.affiliateUrl ?? ""}
          target="__blank"
          prefetch={false}
          rel="sponsored"
          data-event-label={`${sportsbook?.title?.toLowerCase()}-sportsbook-${
            isTopBanner ? "top-banner" : "left-cta"
          }`}
        >
          {sportsbook?.buttonLabel}
        </Link>
      )}
    </div>
  );
};

export default SportsbookPromoItem;
