import ContentLoader from "react-content-loader";

const SportsbookPromosSkeleton = ({ ...props }) => {
  return (
    <ContentLoader
      speed={2}
      width={335}
      height={80}
      backgroundColor="#f3f3f3"
      className="rounded-lg"
      foregroundColor="#ecebeb"
      animate
      {...props}
      id="sportsbook-promos-skeleton"
    >
      <rect x="9" y="5" rx="0" ry="0" width="335" height="80" />
    </ContentLoader>
  );
};

export default SportsbookPromosSkeleton;
